.mat-mdc-checkbox {
  .mdc-form-field {
    column-gap: 10px;
    padding: 0;
    .mdc-label {
      padding: 0;
      @extend %ellipsis;
    }
  }

  .mdc-checkbox {
    flex: unset;
    width: 16px;
    height: 16px;
    --mdc-checkbox-state-layer-size: 16px;

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
      top: 0;
      left: 0;
      border-width: 1px;
    }

    .mat-mdc-checkbox-touch-target {
      width: 24px;
      height: 24px;
    }

    .mdc-checkbox__ripple {
      display: none;
    }

    .mdc-checkbox__native-control {
      &:enabled {
        &:not(:checked) {
          &:not(:indeterminate) {
            &:not([data-indeterminate='true']) {
              ~ .mdc-checkbox__background {
                border-color: $silver;
              }
            }
          }
        }
      }
    }
  }

  &:hover {
    .mdc-checkbox__native-control {
      &:enabled {
        &:not(:checked) {
          &:not(:indeterminate) {
            &:not([data-indeterminate='true']) {
              & ~ .mdc-checkbox__background {
                border-color: var(--mdc-checkbox-unselected-hover-icon-color);
              }
            }
          }
        }
      }
    }
  }

  &.small {
    .mdc-checkbox {
      width: 11px;
      height: 11px;
      --mdc-checkbox-state-layer-size: 11px;

      .mdc-checkbox__background {
        width: 11px;
        height: 11px;
        top: 0;
        left: 0;
      }
    }
  }
}
