.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-contents {
  display: contents !important;
}

.grow {
  &-1 {
    flex-grow: 1;
  }
}

.gap {
  &-2 {
    gap: 2px;
  }
  &-4 {
    gap: 4px;
  }
  &-6 {
    gap: 6px;
  }
  &-8 {
    gap: 8px;
  }
  &-10 {
    gap: 8px;
  }
  &-20 {
    gap: 20px;
  }
}

.shrink {
  &-0 {
    flex-shrink: 0;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-column {
  flex-direction: column !important;
}

.flex-md-row {
  @media (min-width: 768px) {
    flex-direction: row !important;
  }
}

.text {
  &-size {
    &-7 {
      font-size: 7px !important;
    }
    &-8 {
      font-size: 8px !important;
    }
    &-9 {
      font-size: 9px !important;
    }
    &-10 {
      font-size: 10px !important;
    }
    &-11 {
      font-size: 11px !important;
    }
    &-12 {
      font-size: 12px !important;
    }
    &-13 {
      font-size: 13px !important;
    }
    &-14 {
      font-size: 14px !important;
    }
    &-15 {
      font-size: 15px !important;
    }
    &-16 {
      font-size: 16px !important;
    }
    &-17 {
      font-size: 17px !important;
    }
    &-18 {
      font-size: 18px !important;
    }
    &-19 {
      font-size: 19px !important;
    }
    &-20 {
      font-size: 20px !important;
    }
    &-21 {
      font-size: 21px !important;
    }
    &-23 {
      font-size: 23px !important;
    }
    &-24 {
      font-size: 24px;
    }
    &-26 {
      font-size: 26px;
    }
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
  &-flex-start {
    align-items: flex-start;
  }
  &-flex-end {
    align-items: flex-end;
  }
}

.m {
  &b {
    &-0 {
      margin-bottom: 0;
    }
    &-6 {
      margin-bottom: 6px;
    }
    &-9 {
      margin-bottom: 9px;
    }
    &-10 {
      margin-bottom: 10px;
    }
    &-15 {
      margin-bottom: 15px;
    }
    &-42 {
      margin-bottom: 42px;
    }
  }
  &t {
    &-4 {
      margin-top: 4px;
    }
    &-5 {
      margin-top: 5px;
    }
    &-6 {
      margin-top: 6px;
    }
    &-8 {
      margin-top: 8px;
    }
    &-9 {
      margin-top: 9px;
    }
    &-10 {
      margin-top: 10px;
    }
    &-12 {
      margin-top: 12px;
    }
    &-15 {
      margin-top: 15px;
    }
    &-18 {
      margin-top: 18px;
    }
    &-20 {
      margin-top: 20px;
    }
    &-24 {
      margin-top: 24px;
    }
    &-25 {
      margin-top: 25px;
    }
    &-30 {
      margin-top: 30px;
    }
    &-40 {
      margin-top: 40px;
    }
    &-50 {
      margin-top: 50px;
    }
    &-54 {
      margin-top: 54px;
    }
    &-80 {
      margin-top: 80px;
    }
  }
  &l {
    &-auto {
      margin-left: auto;
    }
    &-4 {
      margin-left: 4px;
    }
    &-5 {
      margin-left: 5px;
    }
    &-6 {
      margin-left: 6px;
    }
    &-8 {
      margin-left: 8px;
    }
    &-10 {
      margin-left: 10px;
    }
    &-12 {
      margin-left: 12px;
    }
    &-16 {
      margin-left: 16px;
    }
    &-18 {
      margin-left: 18px;
    }
    &-19 {
      margin-left: 19px;
    }
    &-20 {
      margin-left: 20px;
    }
  }
  &r {
    &-auto {
      margin-right: auto;
    }
    &-2 {
      margin-right: 2px;
    }
    &-3 {
      margin-right: 3px;
    }
    &-4 {
      margin-right: 4px;
    }
    &-5 {
      margin-right: 5px;
    }
    &-6 {
      margin-right: 6px;
    }
    &-8 {
      margin-right: 8px;
    }
    &-9 {
      margin-right: 9px;
    }
    &-10 {
      margin-right: 10px;
    }
    &-11 {
      margin-right: 11px;
    }
    &-12 {
      margin-right: 12px;
    }
    &-16 {
      margin-right: 16px;
    }
    &-18 {
      margin-right: 18px;
    }
    &-20 {
      margin-right: 20px;
    }
  }
}

.p {
  &-0 {
    padding: 0;
  }
  &t {
    &-10 {
      padding-top: 10px;
    }
    &-12 {
      padding-top: 12px;
    }
    &-14 {
      padding-top: 14px;
    }
  }
  &l {
    &-0 {
      padding-left: 0;
    }
    &-8 {
      padding-left: 8px;
    }
    &-15 {
      padding-left: 15px;
    }
  }
  &r {
    &-2 {
      padding-right: 2px;
    }
    &-4 {
      padding-right: 4px;
    }
    &-6 {
      padding-right: 6px;
    }
    &-8 {
      padding-right: 8px;
    }
  }
}

.bold {
  font-weight: 600;
}

.w-100 {
  width: 100%;
}

.maw-100 {
  max-width: 100%;
}

.maw-50 {
  max-width: 50%;
}

.h-100 {
  height: 100%;
}

.v-align-middle {
  vertical-align: middle !important;
}

.justify {
  &-start {
    justify-content: flex-start !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-between {
    justify-content: space-between !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.ellipsis {
  @extend %ellipsis;
  span {
    display: inline;
  }
  &.e-60 {
    max-width: 60%;
  }
  &.e-40 {
    max-width: 60%;
  }
}

.v-visible {
  visibility: visible;
}

.v-hidden {
  visibility: hidden;
}

.text-align {
  &-center {
    text-align: center;
  }
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-md-6 {
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.disabled-events {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-wrap {
  white-space: nowrap;
}

// Colors
.gray {
  color: $dark-gray;
}

.silver {
  color: $silver;
}

.red {
  color: $attention-red;
}

.error {
  background: $attention-red !important;
}
