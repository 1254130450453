.mat-mdc-menu-panel {
  min-height: auto;
  max-height: 500px;
  min-width: 170px;
  padding: 0;
  width: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
  background: white;
  // to fix not being able to use position: fixed with menu
  will-change: auto;

  @media (max-height: 500px) {
    max-height: 80vh;
  }
  .mat-mdc-menu-content:not(:empty) {
    padding: 0;
  }
  &.craft-menu-panel {
    max-width: none;
  }
  &.menu-width {
    &-md {
      max-width: none;
      .mat-mdc-menu-content {
        width: 300px;
      }
    }
  }
}

.menu-search {
  .svg-icon {
    color: $black;
  }
  input {
    color: $dark-gray;
    width: 100%;
  }
}

.mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  > div:not(.menu-search):not(.footer) {
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .menu-scroll {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
  }
  .mat-mdc-menu-item {
    font-weight: 500;
    height: 41px;
    min-height: unset;
    line-height: 41px;
    .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      column-gap: 8px;
      min-width: 0px;
    }
    &.menu-search {
      background-color: $white;
      z-index: 1;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid $devider-gray;
    }
    &.item-checkbox {
      .mat-checkbox-layout {
        height: 100%;
        width: 100%;
        padding: 0 16px;
        align-items: center;
        .mat-checkbox-inner-container {
          margin: 0 15px 0 0;
        }
        .mat-checkbox-label {
          overflow: hidden;
          max-width: 100%;
          text-overflow: ellipsis;
          line-height: 1.2;
        }
      }
      .checkbox-circle {
        .mat-checkbox-layout {
          .mat-checkbox-inner-container {
            margin-right: 12px;
          }
        }
      }
      .mat-checkbox-label-before {
        .mat-checkbox-layout {
          .mat-checkbox-inner-container {
            margin-right: 0;
            margin-left: 15px;
          }
          .mat-checkbox-label {
            flex-grow: 1;
          }
        }
      }
    }
    &:hover,
    &.selected {
      &:not(.no-hover) {
        color: $active-blue;
      }
    }
    &:hover {
      &:not(.no-hover) {
        a {
          color: rgba(0, 0, 0, 0.87);

          &:hover {
            color: $active-blue;
          }
        }
      }
    }
    &.selected {
      font-weight: 600;
    }
    &.item-divider {
      + .mat-mdc-menu-item {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 16px;
          right: 16px;
          height: 1px;
          background: $devider-gray;
        }
      }
    }
    &.menu-search {
      + .mat-mdc-menu-item {
        &:before {
          left: 0;
          right: 0;
        }
      }
    }
  }
  .mat-mdc-menu-item-submenu-trigger {
    padding-right: 16px;
    &::after {
      display: none;
    }
  }
  .mat-radio-group {
    .mat-radio-button {
      width: 100%;
      padding: 0;
    }
    .mat-radio-label {
      width: 100%;
      padding: 0 16px;
    }
    .mat-radio-label-content {
      display: inline-flex;
      flex-grow: 1;
      padding-left: 12px;
    }
  }
}

.color-circle,
.btn.color-circle {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
}

.dropdown-footer {
  position: relative;
  z-index: 1;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background: $white;
  .btn {
    font-size: 14px;
    color: $gray;
    &.btn-blue {
      min-height: 30px;
      min-width: 68px;
      padding: 0;
      &:disabled {
        background: $active-blue;
        opacity: 0.5;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: $devider-gray;
  }
}
